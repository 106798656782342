import { Link } from "react-router-dom";
import styled from 'styled-components';

export const ToolbarItem = styled(Link)`
  display: none;
  float: left;
  padding: 14px 16px;
  color: black;
  text-decoration: none;

  :hover {
    background-color: #41DB32;
  }

  @media screen and (min-width: 600px) {
    display: block;
    
  }
`;

export const HomeToolbarItemMobile = styled(ToolbarItem)`
  display: block;
  text-align: left;

  @media screen and (min-width: 600px) {
    display: none;
    
  }
`;

export const DropdownItem = styled(ToolbarItem)`
  display:block;
  float: none;
  text-align: left;

  @media screen and (min-width: 600px) {
    display: none;
  }
`;

export const Burger = styled.a`
  float: right;
  padding: 14px 16px;
  color: black;
  :hover {
    background-color: #41DB32;
  }

  @media screen and (min-width: 600px) {
    display: none;
  }
`;

export const ToolbarWrapper = styled.div`
  overflow: hidden;
  position: relative;
  box-shadow: 1px 2px 4px black;

  @media screen and (min-width: 600px) {
    box-shadow: 1px 2px 3px black;
    margin-top: 10px;
  }
`;

