import React from 'react';
import logo from '../../images/logo.svg';
import './app.css';
import styled from 'styled-components';
import { Link } from "react-router-dom";

const CenterContent = styled.div`
  text-align: center;
`;

const Logo = styled.img`
  height: 40vmin;
  pointer-events: none;

  @media (prefers-reduced-motion: no-preference) {
      animation: App-logo-spin infinite 20s linear;
  }
`;

const AppHeader = styled.header`
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`;

const AppLink = styled(Link)`
  color: #61dafb;
`;

function App(): JSX.Element {
  return (
    <CenterContent>
      <AppHeader >
        <Logo src={logo} alt="logo" />
        <AppLink to="/"
        >
          Back to my face
        </AppLink>
      </AppHeader>
    </CenterContent>
  );
}

export default App;
