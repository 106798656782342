import React from 'react';
import styled from 'styled-components';

import face from "../../images/face.jpg";

const BannerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 10px;
    flex-direction:column;
    width: 80%;
    @media screen and (min-width: 600px) {
        flex-direction:row;

    }
`;

const Picture = styled.img`
    width: 100%;
    display:flex;
    max-width: 300px;
    @media screen and (min-width: 600px) {
        width: 50%;
        float: inline-start;
    }
    
`;

const BannerText = styled.div`
    width: 80%;
    padding: 5px 10%;
    font-size: 16px;
    display:flex;
    background-color: white;
    
    @media screen and (min-width: 600px) {
        width: 60%;
        padding: 0;
        float: inline-start;
        padding: 15px;
        transform: translate(-10%, 50%);
    }

    @media screen and (min-width: 768px) {
      font-size: 18px;
      max-width:800px;
    }
`;

const Banner : React.FC = () => {
    return(
        <BannerWrapper>
            <Picture src ={face} alt="my face"/>
            <BannerText>
                Hi, I'm Craig. This is a website I built using 
                React and TypeScript, hosted on AWS. 
                I'm using this as a place for me to have a semi-organised 
                portfolio and a collection of things I find interesting. Have a 
                look around!
            </BannerText>
        </BannerWrapper>
    )
}

export default Banner;