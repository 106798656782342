import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { Toolbar } from "../../components"
import Banner from "./banner";

import Background from "../../images/home-background/dogs.jpg";

const CenterContent = styled.div`
  text-align: center;
`;

const Content = styled.div`
  background-image: url(${Background});
  background-color: white;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* width: 80%; */
  margin-top: 10px;
`;

const Logo = styled.img`
  height: 30vmin;
  pointer-events: none;

  @media (prefers-reduced-motion: no-preference) {
      animation: App-logo-spin infinite 20s linear;
  }
`;

const AppLink = styled(Link)`
  color: #61dafb;
`;

const Homepage :React.FC = () : JSX.Element => {
    return (
        <CenterContent>
            <Toolbar />
            <Content>
              <Banner />
              <AppLink to="/app">
                  App
              </AppLink>
            </Content>
        </CenterContent>
    )
}

export default Homepage;