import React, {useState} from 'react';
import { GiHamburgerMenu } from "react-icons/gi";

import {
    ToolbarWrapper,
    Burger,
    DropdownItem,
    HomeToolbarItemMobile,
    ToolbarItem
} from "./styled-components";

const Toolbar : React.FC = () : JSX.Element => {
    const [clicked, setClicked] = useState(false);
  
    return( 
      <ToolbarWrapper>
        <div>
          <Burger href ="" onClick={(e : React.MouseEvent) => {
              e.preventDefault()  // This stops it from trying to link somewhere
              setClicked(!clicked)
            }
            }>
            <GiHamburgerMenu />
          </Burger>
          {clicked && <DropdownItem to="/" >  Home</DropdownItem>}
          {/* {clicked && <DropdownItem to="/cv" >  CV</DropdownItem>} */}
          {/* {clicked && <DropdownItem to="/projects"> Projects</DropdownItem>} */}
          {/* {clicked && <DropdownItem to="/blog"> Blog (maybe) </DropdownItem>} */}
        </div>
        {!clicked && <HomeToolbarItemMobile to="/">Home</HomeToolbarItemMobile>}
        <ToolbarItem to="/"> Home</ToolbarItem>
        {/* <ToolbarItem to="/cv" >CV</ToolbarItem> */}
        {/* <ToolbarItem to="/projects">Projects</ToolbarItem> */}
        {/* <ToolbarItem to="/blog">Blog (maybe) </ToolbarItem> */}
      </ToolbarWrapper>
    )
  }

export default Toolbar;